<template>
  <router-link :to="{ name: 'home' }">
    <img
      :src="logo.base"
      alt="ManyContent"
      class="h-8 hidden md:block"
    />
    <img
      :src="logo.small"
      alt="ManyContent"
      class="h-8 md:hidden"
    />
  </router-link>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

const logos = {
  manycontent: {
    small: require(`@/assets/icone.png`),
    base: require(`@/assets/logo_colorida.png`)
  },
  ralf: {
    base: require(`@/assets/bader_logo.png`),
    small: require(`@/assets/bader_logo_small.png`)
  },
}

export default defineComponent({
  props: {
    partner: {
      type: String,
      default: 'manycontent'
    },
  },
  
  setup (props) {
    const logo = computed(() => {
      const output = (props.partner in logos) ? props.partner : 'manycontent'
      return logos[output as keyof typeof logos]
    })
    
    return {logo}
  }
})
</script>