<template>
  <div :class="sidebarVisibility" class="ml-64">
    <div
      :class="sidebarBackdropVisibility"
      class="fixed top-0 left-0 right-0 bottom-0 opacity-50 bg-black z-10"
      @click.prevent="toggleSidebar"
    />
    <div class="fixed top-0 left-0 bottom-0 z-20 w-64">
      <div class="flex flex-col justify-between relative bg-white h-screen pt-16 border-r shadow-xl">
        <sidebar-profile />
        <sidebar-routes @toggle-sidebar="toggleSidebar" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import SidebarProfile from './SidebarProfile.vue'
import SidebarRoutes from './SidebarRoutes.vue'

export default defineComponent({
  components: {
    SidebarProfile,
    SidebarRoutes
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'toggle-sidebar'
  ],
  setup (props, { emit }) {
    const sidebarVisibility = computed(() => {
      return props.visible
        ? 'visible table'
        : 'hidden sm:table'
    })

    const sidebarBackdropVisibility = computed(() => {
      return props.visible
        ? 'visible sm:hidden'
        : 'hidden'
    })

    const toggleSidebar = () => {
      emit('toggle-sidebar')
    }

    return {
      sidebarVisibility,
      sidebarBackdropVisibility,
      toggleSidebar
    }
  },
})
</script>

