<template>
  <div class="flex gap-2 mx-2">
    <base-modal
      hide-footer
      v-model:open="isOpen"
      @close="read"
    >
      <template v-slot:button>
        <div class="flex gap-2">
          <div class="w-8 text-center">
            <div
              class="flex justify-center items-center h-8 w-8 bg-white rounded-full border"
              :class="image.type === 'error' ? 'border-red-400 bg-red-50' : 'border-blue-400 bg-blue-50'"
            >
              <i
                :class="[image.icon, image.type === 'error' ? 'text-red-400' : 'text-blue-400']"
              ></i>
            </div>
          </div>
          <div>
            <h4 class="xs:text-xs text-sm font-semibold">{{ notification.title }}</h4>
            <div class="xs:text-xs text-sm opacity-75 w-60 line-clamp-2" v-html="notification.body"></div>
            <small class="opacity-50">{{ toNow(notification.createdAt) }}</small>
          </div>
        </div>
      </template>

      <div class="card">
        <div class="card-header">
          <div class="card-title">
            {{ notification.title }}
          </div>
        </div>
        <div class="card-body">
          <div v-html="notification.body"></div>
        </div>
        <div
          class="card-footer flex justify-end w-full gap-4"
          v-if="showActionButtons"
        >
          <button
            v-if="socialAccountsRouter"
            @click.prevent="execute"
            class="btn"
          >
            {{ $t('nav.settings.social_accounts') }}
          </button>

          <button
            v-if="contentRouter"
            @click.prevent="execute"
            class="btn"
          >
            {{ $t('sentence.see_content') }}
          </button>
        </div>
      </div>
    </base-modal>

    <div class="">
      <button @click.prevent="read" class="flex h-4 items-center justify-center opacity-50 w-4 focus:outline-none">
        <i class="fas fa-times !m-0 !text-xs"></i>
      </button>
    </div>
  </div>
  
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import { useDateFns } from '@/composables/locale/useDateFns'

export default defineComponent({
  components: {
    BaseModal
  },
  props: {
    notification: {
      type: Object,
      required: true
    },
  },

  emits: [
    'read'
  ],

  setup (props, { emit }) {
    const { toNow } = useDateFns()

    const router = useRouter()

    const {
      isActive: isOpen,
      setInactive: closeNotification
    } = useToggle(props.notification.openImmediately)

    const read = () => {
      emit('read')
    }

    const image = computed(() => {
      const output = {
        icon: 'far fa-bell',
        type: 'info'
      }
      if ('context' in props.notification.data) {
        switch (props.notification.data.context) {
          case 'published':
            output.icon = 'far fa-paper-plane'
            output.type = 'success'
            break
          case 'error':
          case 'warning':
            output.icon = 'far fa-bell'
            output.type = 'error'
            break
        }
      }
      return output
    })

    const showActionButtons = computed(() => {
      // if ('context' in props.notification.data) {
      //   return props.notification.data.context === 'error'
      // }
      return true
    })

    const contentRouter = computed(() => {
      if ('contentId' in props.notification.data) {
        return {
          name: 'edit-post',
          params: { id: props.notification.data.contentId }
        }
      }
      return null
    })

    const socialAccountsRouter = computed(() => {
      return {
        name: 'settings-social-medias'
      }
    })

    const execute = () => {
      closeNotification()
      read()

      if (contentRouter.value) {
        router.push(contentRouter.value)
      }
    }

    return {
      isOpen,
      image,
      showActionButtons,
      contentRouter,
      socialAccountsRouter,
      toNow,
      execute,
      read
    }
  }
})
</script>

<style scoped>
/* @layer components {
  .btn i {
    @apply text-xs !important;
  }
} */
</style>