<template>
  <div class="flex flex-col items-center justify-center py-3 mb-2">
    <div class="relative w-18 h-18 mb-2">
      <img
        :src="image"
        class="flex-none w-16 h-16 rounded-full object-cover shadow-lg m-2"
      />
    </div>
    <base-dropdown show-arrow position="left" v-model:open="isDropdownOpen">
      <div class="flex flex-col items-center cursor-pointer">
        <div class="font-semibold">{{ name }}</div>
        <div class="text-xs text-gray-500">{{ category }}</div>
      </div>
      <template v-slot:options>
        <div
          class="flex flex-col bg-white"
        >
          <div class="flex flex-col space-y-2 max-h-80 overflow-y-auto">
            <div
              class="flex flex-row items-start py-1 px-4 hover:bg-gray-50 cursor-pointer"
              v-for="profile in availablesProfiles"
              :key="profile.id"
              @click="changeProfile(profile.id)"
            >
              <div>
                <img
                  class="w-8 h-8 rounded-full object-cover shadow-lg mr-2"
                  :src="profile.thumbnail"
                />
              </div>
              <div>
                <div class="font-semibold">
                  {{ profile.name }}
                </div>
                <div>
                  <i class="fas fa-crown text-yellow-400 text-xs mr-1" v-if="profile.active"></i>
                  <small>{{ profile.category }}</small>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div
            class="my-2 mx-4"
            v-if="hasInactiveProfiles"
          >
            <div>
              <input type="checkbox" v-model="hideInactives">
              <small class="ml-2">{{ $t('sentence.hide_inactive_profiles') }}</small>
            </div>
          </div>
          <!-- <div class="my-2 mx-4">
            <button
              class="btn btn-primary w-full"
              @click="handleCreateProfile"
            >
              {{ $t('action.create_profile') }}
            </button>
          </div> -->
        </div>
      </template>
    </base-dropdown>
    <upgrade-button v-if="lockAccess" class="md:hidden mt-2 transform scale-75" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, toRaw, unref, watch } from 'vue'
import { useStore } from 'vuex'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { getProfileImage } from '@/composables/profile/profileImage'
import { useCreateProfile } from '@/composables/profile/createProfile'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useToggle } from '@/composables/useToggle'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'
import UpgradeButton from '@/components/freeTrial/UpgradeButton.vue'
import localforage from 'localforage'

export default defineComponent({
  components: {
    BaseDropdown,
    UpgradeButton
  },
  setup () {
    const store = useStore()

    const router = useRouter()

    const { t } = useI18n()

    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const { createProfile } = useCreateProfile()

    const {
      profile,
      isFreeTrial,
      lockAccess,
      setCurrent
    } = useProfile()

    const hideInactives = ref(false)

    watch(hideInactives, async () => {
      await localforage.setItem('hide-inactive-profiles-in-sidebar', unref(hideInactives))
    })

    onMounted(async () => {
      hideInactives.value = await localforage.getItem('hide-inactive-profiles-in-sidebar') as boolean
    })

    const profiles = computed(() => toRaw(store.getters['profiles/allExceptCurrent']))

    const name = computed(() => {
      return unref(profile)?.name
    })

    const category = computed(() => {
      const profileData = unref(profile)
      return profileData?.category ? profileData?.category.name : null
    })

    const image = computed(() => {
      return getProfileImage(unref(profile))
    })

    const mapedProfiles = computed(() => {
      return profiles.value.map((profile: any) => {
        const { id, name, profilePick, plan, category } = profile
        const active = plan?.active ? true : false
        const thumbnail = profilePick ? profilePick : require('@/assets/profile/manyrobot-profile.svg')
        return {
          id,
          name,
          thumbnail,
          active,
          category: category?.name ? category?.name : t('sentence.no-category')
        }
      })
    })

    const hasInactiveProfiles = computed(() => {
      return unref(mapedProfiles).some((profile: any) => !profile.active)
    })

    const availablesProfiles = computed(() => {
      return unref(hideInactives)
        ? unref(mapedProfiles).filter((profile: any) => profile.active)
        : unref(mapedProfiles)
    })

    const changeProfile = (profileId: string) => {
      setCurrent(profileId)
      router.replace({ name: 'change-profile' })
      closeDropdown()
    }

    const handleCreateProfile = () => {
      let message = `${t('state.new_profile.message')}`
      if (unref(isFreeTrial)) {
        message = `
          ${message}
          <br><br>
          <div class="alert alert-info flex space-x-2">
            <i class="fas fa-info-circle m-1"></i>
            <div>
              ${t('state.new_profile.trial_message')}
            </div>
          </div>
        `
      }
      Swal.fire({
        title: t('state.new_profile.title'),
        html: message,
        showCancelButton: true,
        confirmButtonText: t('state.new_profile.action_confirm'),
        cancelButtonText: t('state.new_profile.action_cancel')
      }).then(({ value }) => {
        if (value) {
          createProfile()
        }
      })
    }

    return {
      name,
      category,
      image,
      isDropdownOpen,
      availablesProfiles,
      hasInactiveProfiles,
      hideInactives,
      lockAccess,
      changeProfile,
      handleCreateProfile
    }
  }
})
</script>
