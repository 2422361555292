
import UpgradeButton from '@/components/freeTrial/UpgradeButton.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { defineComponent } from 'vue'
import NavbarLogo from './NavbarLogo.vue'
// import NavbarPartnerLogo from './NavbarPartnerLogo.vue'
import NavbarNotifications from './NavbarNotifications.vue'

export default defineComponent({
  components: {
    NavbarLogo,
    // NavbarPartnerLogo,
    NavbarNotifications,
    UpgradeButton,
  },
  emits: [
    'toggle-sidebar'
  ],
  setup (props, { emit }) {
    const toggleSidebar = () => {
      emit('toggle-sidebar')
    }

    const {
      lockAccess,
      partner
    } = useProfile()

    return {
      toggleSidebar,
      lockAccess,
      partner
    }
  }
})
