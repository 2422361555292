<template>
  <header class="py-3 lg:py-0 bg-white border-b shadow-md">
    <div class="flex flex-wrap items-center px-4 h-full justify-between">
      <div class="sm:hidden">
        <button @click.prevent="toggleSidebar" class="focus:outline-none">
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div>
        <!-- <navbar-partner-logo/> -->
        <navbar-logo :partner="partner"/>
      </div>
      <div class="hidden md:flex flex-1 justify-between"></div>
      <div class="md:flex justify-between items-center space-x-4">
        <upgrade-button v-if="lockAccess" class="hidden md:block" />
        <navbar-notifications/>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import UpgradeButton from '@/components/freeTrial/UpgradeButton.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { defineComponent } from 'vue'
import NavbarLogo from './NavbarLogo.vue'
// import NavbarPartnerLogo from './NavbarPartnerLogo.vue'
import NavbarNotifications from './NavbarNotifications.vue'

export default defineComponent({
  components: {
    NavbarLogo,
    // NavbarPartnerLogo,
    NavbarNotifications,
    UpgradeButton,
  },
  emits: [
    'toggle-sidebar'
  ],
  setup (props, { emit }) {
    const toggleSidebar = () => {
      emit('toggle-sidebar')
    }

    const {
      lockAccess,
      partner
    } = useProfile()

    return {
      toggleSidebar,
      lockAccess,
      partner
    }
  }
})
</script>
