
import { computed, defineComponent } from 'vue'
import SidebarProfile from './SidebarProfile.vue'
import SidebarRoutes from './SidebarRoutes.vue'

export default defineComponent({
  components: {
    SidebarProfile,
    SidebarRoutes
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'toggle-sidebar'
  ],
  setup (props, { emit }) {
    const sidebarVisibility = computed(() => {
      return props.visible
        ? 'visible table'
        : 'hidden sm:table'
    })

    const sidebarBackdropVisibility = computed(() => {
      return props.visible
        ? 'visible sm:hidden'
        : 'hidden'
    })

    const toggleSidebar = () => {
      emit('toggle-sidebar')
    }

    return {
      sidebarVisibility,
      sidebarBackdropVisibility,
      toggleSidebar
    }
  },
})
