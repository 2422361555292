<template>
  <div class="flex flex-col justify-between h-full px-2 overflow-y-auto">
    <ul>
      <li
        v-for="(route, index) in primaryRoutes"
        :key="`route-${index}`"
        @click="toggleSidebar"
      >
        <router-link
          :to="{ name: route.to }"
          :class="getRouteClass(route)"
          class="sidebar-menu-item"
        >
          <i :class="route.icon"></i>
          {{ $t(route.name) }}
        </router-link>
      </li>
    </ul>

    <ul class="border-t pt-2">
      <li
        v-for="(route, index) in secondaryRoutes"
        :key="`route-${index}`"
        @click="toggleSidebar"
      >
        <router-link
          v-if="route.type === 'router'"
          :to="{ name: route.to }"
          :class="getRouteClass(route)"
          class="sidebar-menu-item"
        >
          <i :class="route.icon"></i>
          {{ $t(route.name) }}
        </router-link>
        <a
          v-else
          :href="route.to"
          target="_blank"
          class="sidebar-menu-item"
        >
          <i :class="route.icon"></i>
          {{ $t(route.name) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
// import { currentUser } from '@/composables/user/currentUser';
import { useProfile } from '@/composables/profile/useProfile';
import { computed, defineComponent, readonly } from 'vue'
import { useRoute } from 'vue-router'

interface SidebarRoute {
  type: 'router' | 'link';
  to: string;
  icon: string;
  name: string;
  children?: readonly string[];
}

export default defineComponent({
  emits: [
    'toggle-sidebar'
  ],

  setup (props, { emit }) {
    const route = useRoute()

    const {permissions, partner} = useProfile()

    const suportURL = computed(() => {
      if (partner.value === 'ralf') {
        return 'https://bader.es/contacto'
      }
      return 'https://suporte.manycontent.com/'
    })

    // const { user } = currentUser()

    const appRoutes = readonly<SidebarRoute[]>([
      { type: 'router', to: 'home', icon: 'fas fa-home', name: 'nav.sidebar.home' },
      // { type: 'router', to: 'my-posts', icon: 'far fa-folder-open', name: 'nav.sidebar.my_contents', children: ['create-post', 'edit-post'] },
      {
        type: 'router', to: 'contents', icon: 'fas fa-tasks', name: 'nav.sidebar.contents',
        children: ['ready-to-use-posts', 'review-posts', 'idea-generator']
      },
      // { type: 'router', to: 'ready-to-use-posts', icon: 'fas fa-gift', name: 'nav.sidebar.templates' },
      // { type: 'router', to: 'review-posts', icon: 'fas fa-check', name: 'nav.sidebar.review' },
      { type: 'router', to: 'schedule', icon: 'far fa-calendar-alt', name: 'nav.sidebar.schedule' },
      { type: 'router', to: 'analytics', icon: 'fas fa-chart-line', name: 'nav.sidebar.analytics' },
      { type: 'router', to: 'competitors', icon: 'fas fa-project-diagram', name: 'nav.sidebar.competitors' },
      { type: 'router', to: 'chat-manybot', icon: 'far fa-comment-alt', name: 'nav.sidebar.chatbot' },
      // {
      //   type: 'router', to: 'tools', icon: 'fas fa-tools', name: 'nav.sidebar.tools',
      //   children: ['tools-title-generator', 'tools-whatsapp-link', 'tools-persona-generator']
      // },
      { type: 'router', to: 'courses', icon: 'fas fa-graduation-cap', name: 'nav.sidebar.courses' },
    ])
    // .filter(item => {
    //   if (user.value.id !== 'skHi69P8radCAUp0adgipYY6tpi1') {
    //     return item.to !== 'chat-manybot'  
    //   }
    //   return true
    // })

    const secondaryRoutes = readonly<SidebarRoute[]>([
      {
        type: 'router', to: 'settings-profile', icon: 'fas fa-cog', name: 'nav.sidebar.settings',
        children: ['settings-account', 'settings-social-medias', 'unsubscribe-list', 'unsubscribe-survey']
      },
      { type: 'link', to: suportURL.value, icon: 'fas fa-question-circle', name: 'nav.sidebar.help' },
      { type: 'router', to: 'logout', icon: 'fas fa-sign-out-alt', name: 'nav.sidebar.logout' },
    ])

    const primaryRoutes = computed(() => {
      return appRoutes.filter(item => {
        const validations = [
          item.to === 'courses' ? !!permissions.value.readCourses : true
        ]
        return validations.every(v => !!v)
      })
    })

    const getRouteClass = (sidebarRoute: SidebarRoute) => {
      if (sidebarRoute.children && route.name) {
        if (sidebarRoute.children.includes(String(route.name))) {
          return 'router-link-active'
        }
      }
    }

    const toggleSidebar = () => {
      emit('toggle-sidebar')
    }

    return {
      primaryRoutes,
      secondaryRoutes,
      getRouteClass,
      toggleSidebar
    }
  }
})
</script>

<style scoped>
@layer components {
  a {
    @apply transition duration-200 ease-in-out no-underline;
    @apply flex items-center text-gray-600 px-4 py-1 mt-0 text-sm font-semibold rounded;
  }

  a i {
    @apply transition duration-200 ease-in-out no-underline;
    @apply text-gray-400 text-lg w-6 mr-2;
  }

  a:not(.router-link-active):hover {
    @apply bg-gray-600 text-gray-800 bg-opacity-5 no-underline;
    @apply ring-1 ring-gray-200;
  }

  a:not(.router-link-active):hover i {
    @apply text-gray-800
  }

  .router-link-active {
    @apply transition duration-500 ease-in-out no-underline;
    @apply text-blue-500 bg-blue-600 bg-opacity-10 ring-1 ring-blue-200 hover:no-underline cursor-not-allowed;
  }

  .router-link-active i {
    @apply text-blue-500;
  }
}
</style>
