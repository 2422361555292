
import { computed, defineComponent, onMounted, ref, toRaw, unref, watch } from 'vue'
import { useStore } from 'vuex'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import { getProfileImage } from '@/composables/profile/profileImage'
import { useCreateProfile } from '@/composables/profile/createProfile'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useToggle } from '@/composables/useToggle'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'
import UpgradeButton from '@/components/freeTrial/UpgradeButton.vue'
import localforage from 'localforage'

export default defineComponent({
  components: {
    BaseDropdown,
    UpgradeButton
  },
  setup () {
    const store = useStore()

    const router = useRouter()

    const { t } = useI18n()

    const {
      isActive: isDropdownOpen,
      setInactive: closeDropdown
    } = useToggle(false)

    const { createProfile } = useCreateProfile()

    const {
      profile,
      isFreeTrial,
      lockAccess,
      setCurrent
    } = useProfile()

    const hideInactives = ref(false)

    watch(hideInactives, async () => {
      await localforage.setItem('hide-inactive-profiles-in-sidebar', unref(hideInactives))
    })

    onMounted(async () => {
      hideInactives.value = await localforage.getItem('hide-inactive-profiles-in-sidebar') as boolean
    })

    const profiles = computed(() => toRaw(store.getters['profiles/allExceptCurrent']))

    const name = computed(() => {
      return unref(profile)?.name
    })

    const category = computed(() => {
      const profileData = unref(profile)
      return profileData?.category ? profileData?.category.name : null
    })

    const image = computed(() => {
      return getProfileImage(unref(profile))
    })

    const mapedProfiles = computed(() => {
      return profiles.value.map((profile: any) => {
        const { id, name, profilePick, plan, category } = profile
        const active = plan?.active ? true : false
        const thumbnail = profilePick ? profilePick : require('@/assets/profile/manyrobot-profile.svg')
        return {
          id,
          name,
          thumbnail,
          active,
          category: category?.name ? category?.name : t('sentence.no-category')
        }
      })
    })

    const hasInactiveProfiles = computed(() => {
      return unref(mapedProfiles).some((profile: any) => !profile.active)
    })

    const availablesProfiles = computed(() => {
      return unref(hideInactives)
        ? unref(mapedProfiles).filter((profile: any) => profile.active)
        : unref(mapedProfiles)
    })

    const changeProfile = (profileId: string) => {
      setCurrent(profileId)
      router.replace({ name: 'change-profile' })
      closeDropdown()
    }

    const handleCreateProfile = () => {
      let message = `${t('state.new_profile.message')}`
      if (unref(isFreeTrial)) {
        message = `
          ${message}
          <br><br>
          <div class="alert alert-info flex space-x-2">
            <i class="fas fa-info-circle m-1"></i>
            <div>
              ${t('state.new_profile.trial_message')}
            </div>
          </div>
        `
      }
      Swal.fire({
        title: t('state.new_profile.title'),
        html: message,
        showCancelButton: true,
        confirmButtonText: t('state.new_profile.action_confirm'),
        cancelButtonText: t('state.new_profile.action_cancel')
      }).then(({ value }) => {
        if (value) {
          createProfile()
        }
      })
    }

    return {
      name,
      category,
      image,
      isDropdownOpen,
      availablesProfiles,
      hasInactiveProfiles,
      hideInactives,
      lockAccess,
      changeProfile,
      handleCreateProfile
    }
  }
})
