
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '@/components/base/BaseModal.vue'
import { useDateFns } from '@/composables/locale/useDateFns'

export default defineComponent({
  components: {
    BaseModal
  },
  props: {
    notification: {
      type: Object,
      required: true
    },
  },

  emits: [
    'read'
  ],

  setup (props, { emit }) {
    const { toNow } = useDateFns()

    const router = useRouter()

    const {
      isActive: isOpen,
      setInactive: closeNotification
    } = useToggle(props.notification.openImmediately)

    const read = () => {
      emit('read')
    }

    const image = computed(() => {
      const output = {
        icon: 'far fa-bell',
        type: 'info'
      }
      if ('context' in props.notification.data) {
        switch (props.notification.data.context) {
          case 'published':
            output.icon = 'far fa-paper-plane'
            output.type = 'success'
            break
          case 'error':
          case 'warning':
            output.icon = 'far fa-bell'
            output.type = 'error'
            break
        }
      }
      return output
    })

    const showActionButtons = computed(() => {
      // if ('context' in props.notification.data) {
      //   return props.notification.data.context === 'error'
      // }
      return true
    })

    const contentRouter = computed(() => {
      if ('contentId' in props.notification.data) {
        return {
          name: 'edit-post',
          params: { id: props.notification.data.contentId }
        }
      }
      return null
    })

    const socialAccountsRouter = computed(() => {
      return {
        name: 'settings-social-medias'
      }
    })

    const execute = () => {
      closeNotification()
      read()

      if (contentRouter.value) {
        router.push(contentRouter.value)
      }
    }

    return {
      isOpen,
      image,
      showActionButtons,
      contentRouter,
      socialAccountsRouter,
      toNow,
      execute,
      read
    }
  }
})
