
import { computed, defineComponent } from 'vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import NotificationModal from './NotificationModal.vue'
import { firestore } from '@/plugins/firebase'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    BaseDropdown,
    NotificationModal,
  },
  setup () {
    const store = useStore()

    const notifications = computed(() => store.getters['notifications/available'])

    const notificationsCount = computed(() => notifications.value.length)

    const hasNotifications = computed(() => notificationsCount.value > 0)

    const notificationStyle = computed(() => {
      if (!hasNotifications.value) {
        return []
      }

      return ['text-red-500', 'animate-bounce']
    })

    const markAsRead = async (notificationId: string) => {
      await firestore
        .collection('notifications')
        .doc(notificationId)
        .update({ status: 'read' })
    }

    return {
      notifications,
      notificationStyle,
      hasNotifications,
      markAsRead
    }
  }
})
