<template>
  <div>
    <base-dropdown>
      <div class="flex gap-1 items-center">
        <i class="far fa-bell" :class="notificationStyle"></i>
        <span class="text-xs" v-if="hasNotifications">{{notifications.length}}</span>
      </div>
      <template v-slot:options>
        <div class="m-2">
          <h1 class="mb-4">Notificações</h1>
          <div v-if="hasNotifications" class="max-h-[calc(100vh-10rem)] overflow-y-auto">
            <div
              v-for="notification in notifications"
              :key="notification.id"
              class="py-2 hover:bg-gray-100 rounded-md"
            >
              <notification-modal
                :notification="notification"
                @read="markAsRead(notification.id)"
              />
            </div>
          </div>
  
          <div v-else class="p-2">
            Sem notificações
          </div>
        </div>
      </template>
    </base-dropdown>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import BaseDropdown from '@/components/base/BaseDropdown.vue'
import NotificationModal from './NotificationModal.vue'
import { firestore } from '@/plugins/firebase'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    BaseDropdown,
    NotificationModal,
  },
  setup () {
    const store = useStore()

    const notifications = computed(() => store.getters['notifications/available'])

    const notificationsCount = computed(() => notifications.value.length)

    const hasNotifications = computed(() => notificationsCount.value > 0)

    const notificationStyle = computed(() => {
      if (!hasNotifications.value) {
        return []
      }

      return ['text-red-500', 'animate-bounce']
    })

    const markAsRead = async (notificationId: string) => {
      await firestore
        .collection('notifications')
        .doc(notificationId)
        .update({ status: 'read' })
    }

    return {
      notifications,
      notificationStyle,
      hasNotifications,
      markAsRead
    }
  }
})
</script>
