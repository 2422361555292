
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useToggle } from '@/composables/useToggle'
import { useBreakpoints } from '@/composables/useBreakpoints'
import DashboardHeader from './navbar/DashboardHeader.vue'
import DashboardSidebar from './sidebar/DashboardSidebar.vue'
// import MainAlertModal from './MainAlertModal.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    DashboardHeader,
    DashboardSidebar,
    // MainAlertModal
  },
  setup () {
    const store = useStore()
    const { locale } = useI18n()

    const { breakpoints } = useBreakpoints()

    const {
      isActive: isSidebarOpen,
      toggle: toggleSidebar
    } = useToggle(false)

    const isSidebarVisible = computed(() => store.state.dashboard.isSidebarVisible)

    const contentFullWrapper = computed(() => store.state.dashboard.contentFullWrapper)

    const breakpoinStyle = computed(() => {
      return breakpoints.smaller('sm').value ? 'is-mobile' : 'is-not-mobile'
    })

    return {
      toggleSidebar,
      isSidebarOpen,
      isSidebarVisible,
      contentFullWrapper,
      breakpoinStyle,
      locale
    }
  }
})
